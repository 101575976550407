﻿import { createPopper, Placement } from '@popperjs/core';

class Tooltip {
    private reference: any;
    private popper: any;
    private placement: Placement;
    private allowPopover: boolean;
    private hideDelay: number;
    private dotNetObjectReference: any;

    private instance;
    private bindedShow;
    private bindedHide;
    private bindedTimeout;

    constructor(reference, popper, placement, allowPopover, hideDelay, dotNetObjectReference) {
        this.reference = reference;
        this.popper = popper;
        this.placement = placement;
        this.allowPopover = allowPopover;
        this.hideDelay = hideDelay;
        this.dotNetObjectReference = dotNetObjectReference;

        this.bindedShow = this.show.bind(this);
        this.bindedHide = this.hide.bind(this);
        this.bindedTimeout = null;
    }

    init() {
        this.instance = createPopper(this.reference, this.popper, {
            placement: this.placement,
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 0],
                    },
                },
                {
                    name: 'arrow',
                    options: {
                        element: this.popper.querySelector('.o-Tooltip__Arrow'),
                    },
                },
                {
                    name: 'preventOverflow',
                    options: {
                        altBoundary: true,
                        padding: 16
                    },
                },
                {
                    name: 'flip',
                    options: {
                        fallbackPlacements: ['top', 'bottom'],
                    },
                },
            ],
            onFirstUpdate: (state) => {
                this.popper.querySelector('.o-Tooltip__Dialog').style['opacity'] = '1';
            }
        });

        if (this.allowPopover) {
            
            this.popper.querySelector('.o-Tooltip__Close').addEventListener('click', this.bindedHide);

            if (this.hideDelay > 0) {
                this.bindedTimeout = setTimeout(() => {
                    this.bindedHide();
                }, this.hideDelay);
            }
        }
        else {
            this.reference.addEventListener("mouseover", this.bindedShow);
        }
    }

    hide() {
        this.dotNetObjectReference.invokeMethodAsync("Hide");

        if (this.allowPopover) {
            this.popper.querySelector('.o-Tooltip__Close').removeEventListener('click', this.bindedHide);
            this.popper.querySelector('.o-Tooltip__Dialog').style['opacity'] = '0';
            if (this.bindedTimeout != null) {
                clearTimeout(this.bindedTimeout);
            }
            this.instance.destroy();
        }
        else {
            this.reference.removeEventListener("mouseout", this.bindedHide);
            this.reference.addEventListener("mouseover", this.bindedShow);
        }
    } 

    show() {
        this.dotNetObjectReference.invokeMethodAsync("Show");

        if (this.allowPopover == false) {
            this.reference.removeEventListener("mouseover", this.bindedShow);
            this.reference.addEventListener("mouseout", this.bindedHide);
            this.instance.update();
        }
    }
}

export function mountTooltip(reference, popper, placement, allowPopover, hideDelay, dotNetObjectReference) {
    new Tooltip(reference, popper, placement, allowPopover, hideDelay, dotNetObjectReference).init();
}