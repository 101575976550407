﻿class NavigationComponentProvider {
    private static navigationMap = new Map<number, Navigation>();
    private static navigationPaneHeaderMap = new Map<number, NavigationPaneHeader>();

    static getNavigationInstance(element: any, dotNetObjectReference: any): Navigation {
        let id: number = dotNetObjectReference._id;
        let instance: Navigation;

        if (this.navigationMap.has(id)) {
            instance = this.navigationMap.get(id);
        }
        else {
            instance = new Navigation(element, dotNetObjectReference);
            this.navigationMap.set(id, instance);
        }        

        return instance;
    }

    static getNavigationPaneHeaderInstance(element: any, dotNetObjectReference: any): NavigationPaneHeader {
        let id: number = dotNetObjectReference._id;
        let instance: NavigationPaneHeader;

        if (this.navigationPaneHeaderMap.has(id)) {
            instance = this.navigationPaneHeaderMap.get(id);
        }
        else {
            instance = new NavigationPaneHeader(element, dotNetObjectReference);
            this.navigationPaneHeaderMap.set(id, instance);
        }

        return instance;
    }

    static deleteNavigationInstance(dotNetObjectReference: any) {
        let id: number = dotNetObjectReference._id;
        this.navigationMap.delete(id);
    }

    static deleteNavigationPaneHeaderInstance(dotNetObjectReference: any) {
        let id: number = dotNetObjectReference._id;
        this.navigationPaneHeaderMap.delete(id);
    }
}

class Navigation {
    private navigation: any;
    private scrollPosition: number;
    private dotNetObjectReference: any;
    private isSticky: boolean;
    private bindedDetectScroll: any;

    constructor(element, dotNetObjectReference) {
        this.navigation = element;
        this.scrollPosition = 0;
        this.dotNetObjectReference = dotNetObjectReference;

        this.isSticky = false;
    }

    dispose() {
        window.removeEventListener('scroll', this.bindedDetectScroll);
    }

    detectScroll() {
        window.requestAnimationFrame(() => {
            var position = window.scrollY;

            if (position > window.innerHeight / 2) {
                if (document.body.getBoundingClientRect().top < this.scrollPosition && this.isSticky == false) {
                    this.dotNetObjectReference.invokeMethodAsync("EnableSticky");
                    this.isSticky = true;
                }
                this.scrollPosition = document.body.getBoundingClientRect().top;
            } else {
                if (this.isSticky == true) {
                    this.dotNetObjectReference.invokeMethodAsync("DisableSticky");
                    this.isSticky = false;
                }
            }
        });
    }

    watch() {
        this.bindedDetectScroll = this.detectScroll.bind(this);
        window.addEventListener('scroll', this.bindedDetectScroll);
    }

    init() {
        if (this.navigation === null) {
            console.warn('You need to provide an element.');
        }
        else {
            this.watch();
        }
    }

}

class NavigationPaneHeader {
    private navigationPaneHeader: any;
    private scrollPosition: number;
    private dotNetObjectReference: any;
    private isClosed: boolean;
    private bindedDetectScroll: any;

    constructor(element, dotNetObjectReference) {
        this.navigationPaneHeader = element;
        this.scrollPosition = 0;
        this.dotNetObjectReference = dotNetObjectReference;

        this.isClosed = false;
    }

    dispose() {
        window.removeEventListener('scroll', this.bindedDetectScroll);
    }

    detectScroll() {
        window.requestAnimationFrame(() => {
            var position = window.scrollY;

            if (position > window.innerHeight / 2) {
                if (document.body.getBoundingClientRect().top < this.scrollPosition && this.isClosed == false) {
                    this.dotNetObjectReference.invokeMethodAsync("Close");
                    this.isClosed = true;
                }
                this.scrollPosition = document.body.getBoundingClientRect().top;
            } else if (this.isClosed == true) {
                this.dotNetObjectReference.invokeMethodAsync("Open");
                this.isClosed = false;
            }
        });
    }

    watch() {
        this.bindedDetectScroll = this.detectScroll.bind(this);
        window.addEventListener('scroll', this.bindedDetectScroll);
    }

    init() {
        if (this.navigationPaneHeader === null) {
            console.warn('You need to provide an element.');
        }
        else {
            this.watch();
        }
    }

}

export function disposeNavigation(element, dotNetObjectReference) {
    let navigation = NavigationComponentProvider.getNavigationInstance(element, dotNetObjectReference);
    NavigationComponentProvider.deleteNavigationInstance(dotNetObjectReference);
    navigation.dispose();
}

export function watchNavigation(element, dotNetObjectReference) {
    let navigation = NavigationComponentProvider.getNavigationInstance(element, dotNetObjectReference);
    navigation.init();
}

export function disposeNavigationPaneHeader(element, dotNetObjectReference) {
    let navigationPaneHeader = NavigationComponentProvider.getNavigationPaneHeaderInstance(element, dotNetObjectReference);
    NavigationComponentProvider.deleteNavigationPaneHeaderInstance(dotNetObjectReference);
    navigationPaneHeader.dispose();
}

export function watchNavigationPaneHeader(element, dotNetObjectReference) {
    let navigationPaneHeader = NavigationComponentProvider.getNavigationPaneHeaderInstance(element, dotNetObjectReference);
    navigationPaneHeader.init();
}