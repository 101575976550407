﻿import Inputmask from "inputmask";

class DatePickerManager {
    constructor(element, dotNetObjectReference) {

    }

    static focusWidget(datepicker) {
        var widget = datepicker.querySelector('.o-Datepicker__Widget');
        widget.focus();
    }

    static setMask(datepicker, maskFormat, placeholder) {
        var input = datepicker.querySelector('.o-TextField__Input');

        //undoOnEscape must be 'false' to avoid click event trigger
        Inputmask({ alias: 'datetime', inputFormat: maskFormat, placeholder: placeholder, undoOnEscape: false }).mask(input);
    }
}

export function showDatePickerWidget(element) {
    DatePickerManager.focusWidget(element);
}

export function setMask(element, maskFormat, placeholder) {
    DatePickerManager.setMask(element, maskFormat, placeholder);
}