﻿let navbar: Navbar = null;

class Navbar {
    private navbar: any;
    private scrollPosition: number;
    private dotNetObjectReference: any;
    private isSticky: boolean;
    private isTransitioning: boolean;

    private navbarAnimationFrame: any;
    private scrollEvent: any;

    constructor(element, dotNetObjectReference) {
        this.navbar = element;
        this.scrollPosition = 0;
        this.dotNetObjectReference = dotNetObjectReference;

        this.isTransitioning = false;
        this.isSticky = false;
    }

    detectScroll() {
        this.navbarAnimationFrame = window.requestAnimationFrame(() => {
            var position = window.scrollY;

            if (position > window.innerHeight) {
                if (document.body.getBoundingClientRect().top < this.scrollPosition && this.isSticky == false) {
                    this.dotNetObjectReference.invokeMethodAsync("EnableSticky");
                    this.isSticky = true;
                }
                this.scrollPosition = document.body.getBoundingClientRect().top;
            } else {
                if (position > window.innerHeight / 2 && this.isTransitioning == false) {
                    this.dotNetObjectReference.invokeMethodAsync("EnableTransitioning");
                    this.isTransitioning = true;
                } else if (position < window.innerHeight / 2 && this.isTransitioning == true) {
                    this.dotNetObjectReference.invokeMethodAsync("DisableTransitioning");
                    this.isTransitioning = false;
                }

                if (this.isSticky == true) {
                    this.dotNetObjectReference.invokeMethodAsync("DisableSticky");
                    this.isSticky = false;
                }
            }
        });
    }

    watch() {
        this.scrollEvent = this.detectScroll.bind(this);
        window.addEventListener('scroll', this.scrollEvent);
    }

    init() {
        if (this.navbar === null) {
            console.warn('You need to provide an element.');
        }
        else {
            this.watch();
        }
    }

    dispose() {
        window.cancelAnimationFrame(this.navbarAnimationFrame);
        this.navbarAnimationFrame = null;
        window.removeEventListener('scroll', this.scrollEvent);
        this.scrollEvent = null;

        this.navbar = null;
        this.dotNetObjectReference = null;
    }

}

export function watchNavbar(element, dotNetObjectReference) {
    navbar = new Navbar(element, dotNetObjectReference);
    navbar.init();
}

export function disposeNavbar() {
    if (navbar) {
        navbar.dispose();
    }
    navbar = null;
}