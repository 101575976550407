﻿export default class Scroll {
    static slideTo(to: number, duration: number = 500) {
        function move(amount) {
            document.documentElement.scrollTop = amount;
            (document.body.parentNode as HTMLElement).scrollTop = amount;
            document.body.scrollTop = amount;
        }

        function position() {
            return document.documentElement.scrollTop || (document.body.parentNode as HTMLElement).scrollTop || document.body.scrollTop;
        }

        function easeInOutQuad(t, b, c, d) {
            t /= d / 2;
            if (t < 1) {
                return c / 2 * t * t + b
            }
            t--;
            return -c / 2 * (t * (t - 2) - 1) + b;
        };

        function easeInCubic(t, b, c, d) {
            var tc = (t /= d) * t * t;
            return b + c * (tc);
        };

        function inOutQuintic(t, b, c, d) {
            var ts = (t /= d) * t,
                tc = ts * t;
            return b + c * (6 * tc * ts + -15 * ts * ts + 10 * tc);
        };

        var start = position(),
            change = to - start,
            currentTime = 0,
            increment = 20;

        var animateScroll = function () {
            currentTime += increment;
            var val = easeInOutQuad(currentTime, start, change, duration);
            move(val);
            if (currentTime < duration) {
                window.requestAnimationFrame(animateScroll);
            }
        };
        animateScroll();
    }

    static scrollToElement(element: HTMLElement) {
        if (element instanceof HTMLElement) {

            const bodyRect = document.body.getBoundingClientRect();
            const elemRect = element.getBoundingClientRect();
            const offset = elemRect.top - bodyRect.top;

            const navbarHeight = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--bd-Navbar-Height'));
            this.slideTo(offset - navbarHeight);
        }
    }
}