﻿import * as Glide from "@glidejs/glide";

class Carousel {
    private carousel: any;
    private settings: string;

    constructor(element = null, options: string) {
        if (element != null) {
            this.carousel = element;
        }

        this.settings = JSON.parse(options);

        this.init();
    }

    init() {
        if (this.carousel != null) {
            this.mount();
        }
    }

    mount() {
        if (this.carousel.querySelectorAll('.glide__slide').length > 0) {
            new Glide(this.carousel, this.settings).mount();
        }
    }
}

export function mountCarousel(element, options: string) {
    new Carousel(element, options).init();
}