﻿import "lazysizes";
import "lazysizes/plugins/respimg/ls.respimg";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";

import * as Body from "./Interop/Body";
import * as Element from "./Interop/Element";
import * as Viewport from "./Interop/Viewport";

import * as AnchorNavigation from "../Components/AnchorNavigation/AnchorNavigation";
import * as Carousel from "../Components/Carousel/Carousel";
import * as Chat from "../Components/Chat/Chat";
import * as CreditCard from "../Components/CreditCard/CreditCard";
import * as DatePicker from "../Components/DatePicker/DatePicker";
import * as Head from "../Components/Head/Head";
import * as Lightbox from "../Components/Lightbox/Lightbox";
import * as Modal from "../Components/Modal/Modal";
import * as Navbar from "../Components/Navbar/Navbar";
import * as Navigation from "../Components/Navigation/Navigation";
import * as OutboundHandler from "../Components/OutboundHandler/OutboundHandler";
import * as Prerendering from "../Components/Prerendering/Prerendering";
import * as Printing from "../Components/Printing/Printing";
import * as QrCode from "../Components/QrCode/QrCode";
import * as Select from "../Components/Select/Select";
import * as TextField from "../Components/TextField/TextField";
import * as Tooltip from "../Components/Tooltip/Tooltip";

declare global {
    interface Window {
        Bladoor: any;
    }
}

window.Bladoor = {
    AnchorNavigation,
    Body,
    Carousel,
    Chat,
    CreditCard,
    DatePicker,
    Element,
    Head,
    Lightbox,
    Modal,
    Navbar,
    Navigation,
    OutboundHandler,
    Prerendering,
    Printing,
    QrCode,
    Select,
    TextField,
    Tooltip,
    Viewport
};