﻿import Utils from '../Utils/Utils';

export function blur(element) {
    Utils.Dom.blur(element);
}

export function focus(element) {
    Utils.Dom.focus(element);
}

export function getDimension(element) {
    return Utils.Dom.getDimension(element);
}

export function attachHideOnScrollListener(element, target) {
    Utils.Dom.addHideOnScroll(element, target)
}

export function removeAllEvents() {
    Utils.Dom.removeWindowEvents();
}