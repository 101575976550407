﻿import * as GLightbox from "glightbox";

class Lightbox {
    private lightbox: any;
    private items: object;

    constructor(serializedItems: string) {
        if (serializedItems !== "") {
            this.items = JSON.parse(serializedItems);
        }

        this.init();
    }

    init() {
        if (this.items != null) {
            this.lightbox = new GLightbox({
                elements: this.items
            });
        }
    }

    open() {
        if (this.lightbox != null) {
            this.lightbox.open();
        }
    }
}

export function openLightbox(serializedItems: string) {
    new Lightbox(serializedItems).open();
}