﻿export function focusInput(inputElement: any) {
    inputElement.focus();
}

export function preventTextAreaLineBreakOnEnter(textareaElement: any) {
    textareaElement.addEventListener("keydown", (event: KeyboardEvent) => {
        if (event.keyCode == 13 && !event.shiftKey) {
            event.preventDefault();
            return false;
        }
    });
}