﻿class Chat {
    private static instance: Chat;

    private chat: HTMLElement;
    private dotNetObjectReference: any;
    private intersectionObserver: IntersectionObserver;

    private constructor() { }

    public static getInstance(): Chat {
        if (!Chat.instance) {
            Chat.instance = new Chat();
        }

        return Chat.instance;
    }

    initialize(element, dotNetObjectReference) {
        this.chat = element;
        this.dotNetObjectReference = dotNetObjectReference;

        this.defineObserver();
        this.observeConversation();

        this.scrollToLastMessage();
    }

    refresh() {
        this.intersectionObserver.disconnect();
        this.observeConversation();
    }

    defineObserver() {
        this.intersectionObserver  = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting === true) {
                    if (entry.intersectionRatio > 0.5) {
                        this.dotNetObjectReference.invokeMethodAsync("ReadMessage", entry.target.getAttribute('data-message-id'));
                        this.intersectionObserver.unobserve(entry.target);
                    }
                }
            });
        }, { threshold: [0, 0.5, 1] });
    }

    observeConversation() {
        this.chat.querySelectorAll('[data-message-id]').forEach((message) => {
            this.intersectionObserver.observe(message);
        });
    }

    scrollToLastMessage() {
        var scrollHeight = this.chat.querySelector('.c-Chat__Conversation').scrollHeight;
        this.chat.querySelector('.c-Chat__Body').scrollTop = scrollHeight;
    }
}

export function initializeChat(element, dotNetObjectReference) {
    const chat = Chat.getInstance();
    chat.initialize(element, dotNetObjectReference);
}

export function refreshChat() {
    const chat = Chat.getInstance();
    chat.refresh();
}

export function scrollToLastMessage() {
    const chat = Chat.getInstance();
    chat.scrollToLastMessage();
}