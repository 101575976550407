﻿import Utils from '../Utils/Utils';

class BodyManager {
    static isBodyOverflowing: boolean;
    static scrollbarWidth: number;

    static checkScrollbar() {
        const rect = document.body.getBoundingClientRect();
        this.isBodyOverflowing = Math.round(rect.left + rect.right) < window.innerWidth;
        this.scrollbarWidth = this.getScrollbarWidth();
    }

    static getScrollbarWidth() {
        const scrollDiv = document.createElement('div');
        scrollDiv.className = 'u-ScrollbarMeasure';
        document.body.appendChild(scrollDiv);
        const scrollbarWidth = scrollDiv.getBoundingClientRect().width - scrollDiv.clientWidth;
        document.body.removeChild(scrollDiv);
        return scrollbarWidth;
    }
}

export function adjustBodyOffset() {
    const scrollbarWidth = BodyManager.getScrollbarWidth();
    if (scrollbarWidth > 0) {
        document.documentElement.style.setProperty('--bd-Body-Offset', `${scrollbarWidth}px`);
        Utils.Dom.css(document.body, {
            "padding-right": `${scrollbarWidth}px`
        });
    }
}

export function disableBodyScroll() {
    Utils.Dom.addClass(document.documentElement, "u-Overflow--Hidden");
}
    
export function enableBodyScroll() {
    Utils.Dom.removeClass(document.documentElement, "u-Overflow--Hidden");
}

export function resetBodyOffset() {
    document.documentElement.style.setProperty('--bd-Body-Offset', null);
    Utils.Dom.css(document.body, {
        "padding-right": null,
    });
}

export function backTop(target: string) {
    let element = Utils.Dom.getDom(target);
    if (element) {
        Utils.Scroll.scrollToElement(element);
    } else {
        Utils.Scroll.scrollToElement(document.body);
    }
}