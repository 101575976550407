﻿import * as Card from "card";

class CreditCard {
    private elementId: string;
    private creditCard: any;
    private settings: any = {};

    constructor(elementId: string = null, options: any = {}) {
        this.elementId = elementId;
        this.settings = options;

        if (this.elementId !== null && this.settings !== {}) {
            this.init();
            this.bindEvents();
        }
    }

    init() {
        this.creditCard = new Card({
            form: this.settings.form,
            container: this.elementId,
            formatting: true,
            formSelectors: {
                cvcInput: this.settings.cvc.id, 
                expiryInput: this.settings.expiry.id,
                nameInput: this.settings.holder.id,
                numberInput: this.settings.number.id
            },
            messages: {
                validDate: 'valid\ndate',
                monthYear: 'mm/yy'
            },
            placeholders: {
                cvc: this.settings.cvc.placeholder,
                number: this.settings.number.placeholder,
                name: this.settings.holder.placeholder,
                expiry: this.settings.expiry.placeholder,
            },
            debug: false
        });
    }

    bindEvents() {
        var instance = this;

        document.querySelector(instance.settings.number.id).addEventListener('payment.cardType', (event: any = {}) => {
            document.querySelector<HTMLInputElement>(instance.settings.type.id).value = event.detail;
        });
    }
}

export function mountCreditCard(elementId, options: any = {}) {
    new CreditCard(elementId, options);
}