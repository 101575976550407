﻿import Utils from '../../Scripts/Utils/Utils';

var elClickOutsideArray = {};
var elFocusArray = {};

var preventKeyCodes: number[] = [];

function handleClick(e: MouseEvent) {
    for (var elId in elClickOutsideArray) {
        var el = Utils.Dom.getDom(`#${elId}`);
        if (el != null) {
            if (!el.contains(e.target)) {
                elClickOutsideArray[elId].invokeMethodAsync("InvokeClickOutside");
            }
        }
    }
};

function handleFocusOut(e: MouseEvent) {
    setTimeout(function () {
        for (var elId in elFocusArray) {
            var el = Utils.Dom.getDom(`#${elId}`);
            if (el != null) {
                if (!el.contains(document.activeElement)) {
                    elFocusArray[elId].invokeMethodAsync("InvokeFocusOutside");
                }
            }
        }
    }, 1);
};

function handleFocusIn(e: MouseEvent) {
    setTimeout(function () {
        for (var elId in elFocusArray) {
            var el = Utils.Dom.getDom(`#${elId}`);
            if (el != null) {
                if (el.contains(e.target)) {
                    elFocusArray[elId].invokeMethodAsync("InvokeFocusInside");
                    break;
                }
            }
        }
    }, 100);
};

function getElClickOutsideCount() {
    var count = 0;
    for (var x in elClickOutsideArray) {
        count++;
    }
    return count;
}

function getElFocusCount() {
    var count = 0;
    for (var x in elFocusArray) {
        count++;
    }
    return count;
}

function handleKeydown(e: KeyboardEvent) {
    // Check keys to preventDefault
    if (preventKeyCodes.indexOf(e.keyCode) > -1) {
        e.preventDefault();
    }
};

function preventKeydown(keyCodes: number[], preventDefault: boolean) {
    if (preventDefault) {
        if (preventKeyCodes.length == 0) {
            window.addEventListener("keydown", handleKeydown);
        }
        keyCodes.forEach(function (keyCode) {
            if (preventKeyCodes.indexOf(keyCode) == -1) {
                preventKeyCodes.push(keyCode);
            }
        });
    }
    else {
        keyCodes.forEach(function (keyCode) {
            const index = preventKeyCodes.indexOf(keyCode, 0);
            if (index > -1) {
                preventKeyCodes.splice(index, 1);
            }
        });
        if (preventKeyCodes.length == 0) {
            window.removeEventListener("keydown", handleKeydown);
        }
    }
}

export function registerClickEvent(element: Element, dotnetHelper): void {
    elClickOutsideArray[element.id] = dotnetHelper;
    document.addEventListener("click", handleClick);
}

export function unregisterClickEvent(element: Element): void {
    delete elClickOutsideArray[element.id];

    if (getElClickOutsideCount() == 0) {
        document.removeEventListener("click", handleClick);
    }
}

export function registerFocusEvents(element: Element, dotnetHelper): void {
    elFocusArray[element.id] = dotnetHelper;
    element.addEventListener("focusout", handleFocusOut);
    element.addEventListener("focusin", handleFocusIn);
}

export function unregisterFocusEvents(element: Element): void {
    delete elFocusArray[element.id];

    if (getElFocusCount() == 0) {
        document.removeEventListener("focusout", handleFocusOut);
        document.removeEventListener("focusin", handleFocusIn);
    }
}

export function preventArrowsKeydown(preventDefault: boolean): void {
    preventKeydown([37, 38, 39, 40], preventDefault);
}

export function preventTabKeydown(preventDefault: boolean): void {
    preventKeydown([9], preventDefault);
}

export function preventPagesKeydown(preventDefault: boolean): void {
    preventKeydown([33, 34], preventDefault);
}

export function preventSpacebarKeydown(preventDefault: boolean): void {
    preventKeydown([32], preventDefault);
}