﻿export default class Dom {
    static getDom(element) {
        if (!element) {
            element = document.body;
        } else if (typeof element === 'string') {
            if (element === 'document') {
                return document;
            }
            element = document.querySelector<HTMLElement>(element);
        }
        return element;
    }

    static css(element: HTMLElement, name: string | object, value: string | null = null) {
        if (typeof name === 'string') {
            element.style[name] = value;
        } else {
            for (let key in name) {
                if (name.hasOwnProperty(key)) {
                    element.style[key] = name[key];
                }
            }
        }
    }

    static addClass(selector: Element | string, className: string | Array<string>) {
        let element = this.getDom(selector);

        if (typeof className === "string") {
            element.classList.add(className);
        } else {
            element.classList.add(...className);
        }
    }

    static removeClass(selector: Element | string, className: string | Array<string>) {
        let element = this.getDom(selector);

        if (typeof className === "string") {
            element.classList.remove(className);
        } else {
            element.classList.remove(...className);
        }
    }

    static focus(selector) {
        let dom = this.getDom(selector);
        dom.focus();
    }

    static blur(selector) {
        let dom = this.getDom(selector);
        dom.blur();
    }

    static getDimension(selector) {

        let dom = this.getDom(selector);

        if (dom) {
            return {
                Width: dom.clientWidth,
                Height: dom.clientHeight
            }
        }

        return null;
    }

    //Dictionary contenente tutti gli eventi attaccati alla window
    static windowEvents: { [key: string]: EventListener } = {};

    static addHideOnScroll(elemToHide, targetToReach) {
        const onScrollFunction = (args) => { this.hideElemWhenOnTarget(elemToHide, targetToReach) };
        this.windowEvents["scroll"] = onScrollFunction;

        window.addEventListener("scroll", onScrollFunction);
    }

    static removeWindowEvents() {

        Object.keys(this.windowEvents).forEach((key) => {
            window.removeEventListener(key, this.windowEvents[key]);
            delete this.windowEvents[key];
        })
    }

    //Nasconde un elemento del DOM quando si scrolla fino all'elemento desiderato
    static hideElemWhenOnTarget(elemToHide, targetToReach) {
        var domElem = this.getDom(elemToHide);
        var domTarget = this.getDom(targetToReach);

        if (domTarget.getBoundingClientRect().top < domElem.getBoundingClientRect().top)
            domElem.style.visibility = "hidden";
        else
            domElem.style.visibility = "visible";
    }
}