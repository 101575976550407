﻿import { adjustBodyOffset, disableBodyScroll, enableBodyScroll, resetBodyOffset } from '../../Scripts/Interop/Body';

class ModalManager {
    static focusDialog(modal) {
        var dialog = modal.querySelector('.c-Modal__Dialog');
        dialog.focus();
    }
}

export function showModal(element) {
    adjustBodyOffset();
    disableBodyScroll();
    ModalManager.focusDialog(element);
}

export function hideModal() {
    resetBodyOffset();
    enableBodyScroll();
}