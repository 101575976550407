﻿import * as Qr from "qrcodejs2";

export function generateQrCode(element, text: string, options: object) {
    if (element != null) {
        element.innerHTML = "";
        new Qr(element, {
            text: text,
            correctLevel: Qr.CorrectLevel.Q
        });
    }
}