﻿import * as printJS from "print-js";
import Utils from '../../Scripts/Utils/Utils';

export function print(options) {
    if (Utils.Device.isMobile()) {
        if (options.base64) {
            const base64Pdf = options.printable;
            const blob = blobPdfFromBase64String(base64Pdf);
            downloadBlob(blob);
        }
    }
    else {
        printJS(options);
    }
}


const blobPdfFromBase64String = base64String => {
    const byteArray = Uint8Array.from(
        atob(base64String)
            .split('')
            .map(char => char.charCodeAt(0))
    );
    return new Blob([byteArray], { type: 'application/pdf' });
};


const downloadBlob = (blob) => {
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, '_blank')
};