﻿export function scrollToTopIndex(element, index: number) {
    var container = element.querySelector('.o-Select__List');
    if (container !== null) {
        container.scrollTop = 25 * index;
    }
}

export function focusElement(element) {
    var input = element.querySelector('.o-TextField__Input');
    input.focus();
}