﻿class HeadManager {
    static blazorIdAttributeName = '_bladoor_id';

    static createHeadTag(headElement, id = null) {
        const tagElement = document.createElement(headElement.tagName);

        // The id is undefined during prerendering
        if (id != null) {
            tagElement.setAttribute(this.blazorIdAttributeName, id);
        }

        if (headElement.attributes) {
            Object.keys(headElement.attributes).forEach(key => {
                tagElement.setAttribute(key, headElement.attributes[key]);
            });
        }

        if (headElement.content) {
            var content = headElement.content.replace(/\n|\t/g, ' ');
            tagElement.appendChild(document.createTextNode(content));
        }

        document.head.appendChild(tagElement);

        return tagElement;
    }
}

export function setTitle(title) {
    document.title = title;
}

export function removeHeadTag(id) {
    let tag = document.head.querySelector(`[${HeadManager.blazorIdAttributeName}='${id}']`);
    tag && tag.remove();
}

export function addOrUpdateHeadTag(tag, id) {
    removeHeadTag(id);
    HeadManager.createHeadTag(tag, id);
}