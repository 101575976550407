﻿import Utils from '../Utils/Utils';

class BreakpointsSniffer {
    MqType: { MIN: string; MAX: string; };
    Breakpoints: any;
    Breakpoint: string;

    constructor() {
        this.MqType = {
            MIN: 'min',
            MAX: 'max'
        };

        var breakpoints = this.breakpoints;

        if (breakpoints !== "none") {
            this.Breakpoints = JSON.parse(breakpoints);

            this.Breakpoint = this.breakpoint;
            window.addEventListener('resize', () => {
                this.Breakpoint = this.breakpoint;

            }, false);
        }
    }

    get breakpoint() {
        return window.getComputedStyle(document.body, ':after').content.replace(/\"/g, '');
    }

    get breakpoints() {
        return Utils.Characters.removeQuotes(window.getComputedStyle(document.body, ':before').content);
    }

    match(type, breakpoint) {
        if (type === this.MqType.MAX) {
            breakpoint--;
        }

        var mq = window.matchMedia(`screen and (${type}-width: ${breakpoint})`);
        return mq.matches ? true : false;
    }

    is(breakpoint) {
        return this.Breakpoint === breakpoint;
    }

    isMax(breakpoint) {
        return this.match(this.MqType.MAX, this.Breakpoints[breakpoint]);
    }

    isMin(breakpoint) {
        return this.match(this.MqType.MIN, this.Breakpoints[breakpoint]);
    }
}

class ViewportManager {

    static sniffer = new BreakpointsSniffer();

    static calculateViewportHeight() {
        let vh = window.innerHeight * 0.01;
        Utils.Dom.getDom('body').style.setProperty('--vh', `${vh}px`);
    }
}

export function setViewportUnit() {
    ViewportManager.calculateViewportHeight();

    window.addEventListener('resize', () => {
        ViewportManager.calculateViewportHeight();
    });
}

export function match(type, breakpoint) {
    return ViewportManager.sniffer.match(type, breakpoint);
}

export function is(breakpoint) {
    return ViewportManager.sniffer.is(breakpoint);
}

export function isMax(breakpoint) {
    return ViewportManager.sniffer.isMax(breakpoint);
}

export function isMin(breakpoint) {
    return ViewportManager.sniffer.isMin(breakpoint);
}

export function isMobile() {
    return Utils.Device.isMobile();
}

export function isTouch() {
    return "ontouchstart" in window;
}